import React, { useMemo } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Typography, Row, Col } from 'antd';

import Avatar from 'components/Shared/User/Avatar';
import BookmarksList from 'components/Contacts/RelationCard/BookmarkList';
import Link from 'components/Shared/Common/Link';

import Style from './styles';

const { Title, Text } = Typography;
const styleTitle = { margin: 0 };

type CallingModalLayoutProps = {
    _room?: string,
    transferToPhonenumber?: boolean,
    avatarUri?: any,
    callerName: string,
    callerPhoneNumber: string,
    description: string,
    body: any,
    actions: any,
    isCrmData?: boolean,
    url?: string
    source?: string
} & WrappedComponentProps;

const CallingModalLayout = ({
    _room = '',
    transferToPhonenumber = false,
    avatarUri = '',
    callerName,
    callerPhoneNumber,
    description,
    body,
    isCrmData = false,
    url,
    source,
    actions,
    intl
}: CallingModalLayoutProps) => {
    const translate = useMemo(() => ({navigate: intl.formatMessage({ id: 'menuLeft.navigation.to' }, {name: intl.formatMessage({ id: `thirdPartyConfigurations.app.${source}` })})}), [intl]);
    return (
        <Row justify="center" wrap={false}>
            <Col>
                <Style>
                    <div className="call-incoming">
                        <div className="avatar-container">
                            <div className="avatar-animated-container">
                                <div className="bg-circle" />
                                <div className="bg-circle" />
                                <div className="bg-circle" />
                                <div className="bg-circle" />
                                <Avatar
                                    src={avatarUri}
                                    size="xxxl"
                                    alt={callerName}
                                    hasStatus={false}
                                />
                            </div>
                        </div>
                        <div className="text-container">
                            {callerPhoneNumber && (<Title level={4}>{callerPhoneNumber}</Title>)}
                            <Title
                                className="title"
                                style={styleTitle}
                                level={5}
                            >
                                {callerName}
                            </Title>
                            {isCrmData ? (<Text type={"secondary"}>
                                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                {/* @ts-ignore */}
                                <Link href={url} target={'_blank'}>{translate.navigate}</Link>
                            </Text>
                            ): null}
                            <Text className="desc">{description}</Text>
                        </div>
                        {body}
                        {actions && (
                            <div className="action-container">
                                {actions}
                            </div>
                        )}
                    </div>
                </Style>
            </Col>
            {transferToPhonenumber && (
                <Col>
                    <BookmarksList _room={_room} />
                </Col>
            )}
        </Row>
    );
}

export default injectIntl(CallingModalLayout);
